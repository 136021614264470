import React, {FC} from "react";
import {useWidgetDependencies} from "../utils/WidgetUtils";

const DepositWidget: FC = () => {
    useWidgetDependencies("ma4b-deposit");

    return (
        <div
            id="ma4b-deposit"
            data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
            data-layout="card-right"
            data-deposit-flow-url="https://engine.ma4b.io/flow/widget-deposit"
            data-activate-nuvei="true"
            data-activate-klarna="true"
            data-activate-paypal="true"
            data-activate-paysafe="true"
            data-activate-skrill="true"
            data-activate-trustly="true"
            data-activate-truevo="true"
        />
    )
};

export default DepositWidget;