import React, {FC} from "react";

const NotFound: FC = () => {
    return (
        <div className="h-full bg-white dark:bg-zinc-900 flex flex-col justify-center items-center gap-8 lg:py-60 md:py-32 sm:py-16 py-8">
            <p className="text-4xl dark:text-white">404 Not Found</p>
            <p className="text-lg text-zinc-600 dark:text-zinc-500">No Page Was Found For This Route.</p>
        </div>
    )
};

export default NotFound;