import React, {FC} from "react";
import {useWidgetDependencies} from "../utils/WidgetUtils";

const NavigationWidget: FC = () => {
    useWidgetDependencies("ma4b-account");

    return (
        <div
            id="ma4b-account"
            data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
            data-layout="card-right"
            data-href-deposits="/deposits"
            data-href-withdrawal="/withdrawal"
            data-support-email-address="netxbetting@wearegroup.de"
        />
    )
};

export default NavigationWidget;