import React, {FC} from "react";

type FooterProps = {
    className?: string;
    description: string;
}

const Footer: FC<FooterProps> = ({className, description}) => {
    return (
        <span className={`${className} w-full flex justify-center items-center px-14 py-8 text-zinc-500 dark:text-zinc-950 border-t border-zinc-200 dark:border-zinc-950`}>{description}</span>
    )
}

export default Footer;