import React, {FC} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import WidgetWithNavigation from "./components/WidgetWithNavigation";

const HostedPage: FC = () => {
    return (
        <div className="h-full min-w-full flex flex-col">
            <Header className="sticky top-0 z-50 bg-white shadow-md" title='Player One Betting'/>

            <WidgetWithNavigation/>

            <Footer className="sticky bottom-0 z-50 shadow-md"
                    description={'© Copyright 2024 NetX Betting Limited. All rights reserved.'}/>
        </div>
    );
}

export default HostedPage;
