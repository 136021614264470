import {useEffect} from "react";

export function useWidgetDependencies(widgetName: string) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://cdn.ma4b.io/${widgetName}.min.js`;
        document.body.appendChild(script);

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://cdn.ma4b.io/${widgetName}.min.css`;
        document.head.appendChild(link);

        return () => {
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, [widgetName]);
}