import React, {FC} from "react";
import {useWidgetDependencies} from "../utils/WidgetUtils";

const MyBetsWidget: FC = () => {
    useWidgetDependencies("ma4b-my-bets");

    return (
        <div
            id="ma4b-my-bets"
            data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
            data-layout="card-right"
        />
    )
};

export default MyBetsWidget;