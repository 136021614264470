import React, {FC} from "react";
import NavigationWidget from "./NavigationWidget";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AccountWidget from "./AccountWidget";
import DepositWidget from "./DepositWidget";
import MyBetsWidget from "./MyBetsWidget";
import NotFound from "./NotFound";

const router = createBrowserRouter([
    {
        path: "/account",
        element: <AccountWidget/>,
    },
    {
        path: "/deposits",
        element: <DepositWidget/>,
    },
    {
        path: "/my-bets",
        element: <MyBetsWidget/>,
    },
    {
        path: "*",
        element: <NotFound/>,
    },
]);


const WidgetWithNavigation: FC = () => {
    return (
        <div className="flex-grow flex overflow-hidden overflow-y-auto py-8">
            {/* Sticky Navigation */}
            <div className="sticky z-10 top-0 w-2/12 self-start">
                <NavigationWidget/>
            </div>

            {/* Widget */}
            <div
                className="w-10/12 h-fit max-h-full overflow-y-auto rounded-l-lg border-y border-l border-zinc-100 dark:border-white">
                <React.StrictMode>
                    <RouterProvider router={router} />
                </React.StrictMode>
            </div>
        </div>
    )
}

export default WidgetWithNavigation;